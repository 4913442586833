import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase, { db } from "services/firebase";
import { nanoid } from "nanoid";
import { getUsername } from "helpers/user";
import { getNewDeck, CoupGame } from "helpers/coup";
import styles from "./Home.module.scss";

const Home = () => {
  const history = useHistory();

  useEffect(() => {
    async function fetchGames() {
      try {
        db.ref("games").on("value", (snapshot) => {
          const games: any = [];
          snapshot.forEach((snap) => {
            games.push(snap.val());
          });
        });
      } catch (error) {
        console.error(error);
      }
    }

    fetchGames();
  }, []);

  const createGame = async () => {
    try {
      const newId = nanoid(8);

      // create a new deck and deal 2 cards
      const deck = getNewDeck();
      const firstPlayerCharacters = deck.splice(0, 2);
      const cards = [];
      for (let j = 0; j < firstPlayerCharacters.length; j += 1) {
        cards.push({
          character: firstPlayerCharacters[j],
          revealed: false,
        });
      }

      const newGame: CoupGame = {
        id: newId,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        currentDeck: deck,
        players: [
          {
            name: getUsername(),
            coins: 2,
            cards,
          },
        ],
        revealedCards: [],
      };

      await db.ref(`games/${newId}`).set(newGame);
      history.push(`/play/${newId}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div>
          <h1 className={styles.header}>Virtual Coup Lobby</h1>
        </div>
        <div className={styles.buttonContainer}>
          <button
            type="button"
            className={styles.createButton}
            onClick={createGame}
          >
            Create Game
          </button>
          <form className={styles.joinForm}>
            <input className={styles.joinInput} type="text" />
            <button type="submit" className={styles.joinButton}>
              Join Game
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Home;
