import React, { useState } from "react";
import { CoupGame, Card } from "helpers/coup";
import { db } from "services/firebase";
import { getUserPlayer } from "helpers/user";
import { useParams } from "react-router-dom";
import styles from "./RevealButtons.module.scss";

interface RevealButtonsProps {
  game: CoupGame;
}

const RevealButtons = (props: RevealButtonsProps) => {
  interface ParamTypes {
    id: string;
  }
  const { game } = props;
  const { players } = game;
  const currentPlayer = getUserPlayer(game);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<ParamTypes>();

  const revealCard = async (cardIdx: number) => {
    if (!currentPlayer) return;

    setLoading(true);
    const { cards } = currentPlayer;

    const newCards = [];
    for (let i = 0; i < cards.length; i += 1) {
      if (i === cardIdx) {
        const newCard: Card = {
          character: cards[i].character,
          revealed: true,
        };
        newCards.push(newCard);
      } else {
        newCards.push(cards[i]);
      }
    }

    const updatedPlayer = {
      ...currentPlayer,
      cards: newCards,
    };

    const newPlayers = [];
    for (let i = 0; i < players.length; i += 1) {
      const player = players[i];
      if (player.name === currentPlayer.name) {
        newPlayers.push(updatedPlayer);
      } else {
        newPlayers.push(player);
      }
    }

    try {
      await db.ref(`games/${id}/players`).set(newPlayers);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const hideCard = async (cardIdx: number) => {
    if (!currentPlayer) return;

    setLoading(true);
    const { cards } = currentPlayer;

    const newCards = [];
    for (let i = 0; i < cards.length; i += 1) {
      if (i === cardIdx) {
        const newCard: Card = {
          character: cards[i].character,
          revealed: false,
        };
        newCards.push(newCard);
      } else {
        newCards.push(cards[i]);
      }
    }

    const updatedPlayer = {
      ...currentPlayer,
      cards: newCards,
    };

    const newPlayers = [];
    for (let i = 0; i < players.length; i += 1) {
      const player = players[i];
      if (player.name === currentPlayer.name) {
        newPlayers.push(updatedPlayer);
      } else {
        newPlayers.push(player);
      }
    }

    try {
      await db.ref(`games/${id}/players`).set(newPlayers);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {currentPlayer?.cards.map((card, idx) => {
        if (loading) {
          return (
            <button type="button" className={styles.button} disabled>
              Loading...
            </button>
          );
        }

        if (card.revealed) {
          return (
            <button
              type="button"
              className={styles.button}
              onClick={() => hideCard(idx)}
            >
              Hide {card.character}
            </button>
          );
        }

        return (
          <button
            type="button"
            className={styles.button}
            onClick={() => revealCard(idx)}
          >
            Reveal {card.character}
          </button>
        );
      })}
    </div>
  );
};

export default RevealButtons;
