import {
  uniqueNamesGenerator,
  Config,
  colors,
  animals,
} from "unique-names-generator";
import { CoupGame, Character, Player, Card } from "./coup";

export const getUsername = (): string => {
  const username = localStorage.getItem("username");
  if (username) {
    return username;
  }

  const config: Config = {
    dictionaries: [colors, animals],
    separator: "",
    style: "capital",
  };
  const newUsername = uniqueNamesGenerator(config);
  setUsername(newUsername);
  return newUsername;
};

export const setUsername = (newUsername: string) => {
  localStorage.setItem("username", newUsername);
};

export const userInGame = (currentGame: CoupGame): boolean => {
  const currentPlayer = getUsername();
  const { players } = currentGame;
  if (!players) return false;
  for (let i = 0; i < players.length; i += 1) {
    const player = players[i];
    if (player.name === currentPlayer) {
      return true;
    }
  }
  return false;
};

export const getUserPlayer = (currentGame: CoupGame): Player | undefined => {
  const currentPlayer = getUsername();
  const { players } = currentGame;
  if (!players) return undefined;

  for (let i = 0; i < players.length; i += 1) {
    const player = players[i];
    if (player.name === currentPlayer) {
      return player;
    }
  }

  return undefined;
};

export const getUserCards = (currentGame: CoupGame): Array<Card> => {
  const userCards: Array<Card> = [];
  const currentPlayer = getUsername();
  const { players } = currentGame;
  if (!players) return userCards;

  for (let i = 0; i < players.length; i += 1) {
    const player = players[i];
    if (player.name === currentPlayer) {
      return player.cards;
    }
  }
  return userCards;
};

export const getUserCharacters = (currentGame: CoupGame): Array<Character> => {
  const userCharacters: Array<Character> = [];
  const currentPlayer = getUsername();
  const { players } = currentGame;
  if (!players) return userCharacters;

  for (let i = 0; i < players.length; i += 1) {
    const player = players[i];
    if (player.name === currentPlayer) {
      for (let j = 0; j < player.cards.length; j += 1) {
        userCharacters.push(player.cards[j].character);
      }
    }
  }
  return userCharacters;
};
