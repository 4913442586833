import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { FiX } from "react-icons/fi";
import { db } from "services/firebase";
import { getNewDeck, CoupGame, Player } from "helpers/coup";
import styles from "./ResetGameModal.module.scss";

interface Props {
  handleClose: () => void;
  game: CoupGame;
}

const ResetGameModal = (props: Props) => {
  interface ParamTypes {
    id: string;
  }
  const [loading, setLoading] = useState(false);
  const { id } = useParams<ParamTypes>();
  const { handleClose, game } = props;
  const { players } = game;

  const handleSubmit = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    setLoading(true);

    // resetting the game keeps the players
    // but creates a new deck and deals the cards again
    const deck = getNewDeck();

    const newPlayers: Array<Player> = [];
    for (let i = 0; i < players.length; i += 1) {
      const characters = deck.splice(0, 2);
      const cards = [];
      for (let j = 0; j < characters.length; j += 1) {
        cards.push({
          character: characters[j],
          revealed: false,
        });
      }

      newPlayers.push({
        ...players[i],
        coins: 2,
        cards,
      });
    }

    const newGame: CoupGame = {
      ...game,
      currentDeck: deck,
      players: newPlayers,
      revealedCards: [],
    };

    try {
      await db.ref(`games/${id}`).set(newGame);
      setLoading(false);
      handleClose();
    } catch {
      setLoading(false);
    }
  };

  const resetGameButton = () => {
    if (loading) {
      return <div className={styles.resetGameDisabled}>Resetting...</div>;
    }

    return (
      <div className={styles.resetGame} onClick={handleSubmit}>
        Reset Game
      </div>
    );
  };

  return (
    <div className={styles.background} onClick={handleClose}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          Reset Current Game
          <div className={styles.close} onClick={handleClose}>
            <FiX />
          </div>
        </div>
        <div className={styles.description}>
          Resetting the current game will keep the same players, but reset all
          coins to 2, and deal brand new hands to every player. This will reset
          your current game.
        </div>
        <div className={styles.actions}>
          <div className={styles.cancel} onClick={handleClose}>
            Cancel
          </div>
          {resetGameButton()}
        </div>
      </div>
    </div>
  );
};

export default ResetGameModal;
