import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import GameContainer from "components/GameContainer";
import { getUsername } from "helpers/user";

const App = () => {
  const username = getUsername();
  console.info("username:", username);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>

        <Route path="/play/:id" component={GameContainer} />

        {/* add 404 route */}
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
