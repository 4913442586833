import React from "react";
import { Player, Card } from "helpers/coup";
import { getUsername } from "helpers/user";
import styles from "./PlayerCard.module.scss";

interface PlayerProps {
  player: Player;
}

const PlayerCard = (props: PlayerProps) => {
  const { player } = props;

  const isCurrentPlayer = player.name === getUsername();

  const renderMyCard = (card: Card) => (
    <div>
      <div>{card.character}</div>
      {card.revealed ? (
        <div className={styles.revealed}>Revealed</div>
      ) : (
        <div className={styles.hidden}>Face down</div>
      )}
    </div>
  );

  const renderOtherCard = (card: Card) => (
    <div>{card.revealed ? card.character : "-"}</div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.playerCards}>
          {player.cards.map((card) => (
            <div className={styles.card}>
              {isCurrentPlayer ? renderMyCard(card) : renderOtherCard(card)}
            </div>
          ))}
        </div>
        <div className={styles.details}>
          <div className={styles.name}>
            {player.name}{" "}
            <span className={styles.you}>
              {isCurrentPlayer ? "(You)" : null}
            </span>
          </div>
          <div className={styles.playerCoins}>{player.coins} coins</div>
        </div>
      </div>
    </div>
  );
};

export default PlayerCard;
