import React from "react";
import { CoupGame, Player } from "helpers/coup";
import PlayerCard from "./PlayerCard";
import Actions from "./Actions";
import styles from "./Game.module.scss";

interface Props {
  game: CoupGame;
}

const Game = (props: Props) => {
  const { game } = props;
  return (
    <>
      <div className={styles.players}>
        {game.players.map((player: Player) => (
          <PlayerCard player={player} key={player.name} />
        ))}
      </div>
      <Actions game={game} />
    </>
  );
};

export default Game;
