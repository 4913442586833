import React, { useState } from "react";
import { CoupGame } from "helpers/coup";
import { db } from "services/firebase";
import { getUserPlayer } from "helpers/user";
import { useParams } from "react-router-dom";
import RevealButtons from "./actions/RevealButtons";
import SwapButtons from "./actions/SwapButtons";
import styles from "./Actions.module.scss";
import AmbassadorModal from "./modals/AmbassadorModal";

interface ActionsProps {
  game: CoupGame;
}

const Actions = (props: ActionsProps) => {
  interface ParamTypes {
    id: string;
  }
  const { game } = props;
  const { players } = game;
  const [showAmbassadorModal, setShowAmbassadorModal] = useState(false);
  const [loadingCoins, setLoadingCoins] = useState(false);
  const { id } = useParams<ParamTypes>();

  const adjustCoins = async (value: 1 | -1) => {
    const currentPlayer = getUserPlayer(game);

    if (!currentPlayer) return;

    let newCoins = currentPlayer.coins + value;
    if (newCoins < 0) newCoins = 0;

    const updatedPlayer = {
      ...currentPlayer,
      coins: newCoins,
    };

    const newPlayers = [];
    for (let i = 0; i < players.length; i += 1) {
      const player = players[i];
      if (player.name === currentPlayer.name) {
        newPlayers.push(updatedPlayer);
      } else {
        newPlayers.push(player);
      }
    }

    try {
      await db.ref(`games/${id}/players`).set(newPlayers);
      setLoadingCoins(false);
    } catch {
      setLoadingCoins(false);
    }
  };

  const takeCoin = () => {
    adjustCoins(1);
  };

  const payCoin = () => {
    adjustCoins(-1);
  };

  const renderRevealedCards = () => {
    const { revealedCards } = game;
    if (revealedCards && revealedCards.length) {
      return (
        <div className={styles.revealedCards}>
          There are {revealedCards.length} cards revealed. They are:{" "}
          {revealedCards.map((card) => (
            <span className={styles.revealedCard}>{card} </span>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.gameInfo}>
          There are {game.currentDeck.length} cards left the deck. Choose from
          the actions to the right to play on your turn.
          {renderRevealedCards()}
        </div>
        <div className={styles.actions}>
          {loadingCoins ? (
            <button type="button" className={styles.button} disabled>
              Loading ...
            </button>
          ) : (
            <button type="button" className={styles.button} onClick={takeCoin}>
              Take 1 Coin
            </button>
          )}
          {loadingCoins ? (
            <button type="button" className={styles.button} disabled>
              Loading ...
            </button>
          ) : (
            <button type="button" className={styles.button} onClick={payCoin}>
              Pay 1 Coin
            </button>
          )}
          <button
            type="button"
            className={styles.button}
            onClick={() => {
              setShowAmbassadorModal(true);
            }}
          >
            Ambassador
          </button>
          <RevealButtons game={game} />
          <SwapButtons game={game} />
        </div>
      </div>

      {showAmbassadorModal ? (
        <AmbassadorModal
          handleClose={() => setShowAmbassadorModal(false)}
          game={game}
        />
      ) : null}
    </>
  );
};

export default Actions;
