import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyCtsuYLDhwBOXmje-RZjvNlTWkAR1gR_N4",
  authDomain: "virtual-coup.firebaseapp.com",
  databaseURL: "https://virtual-coup-default-rtdb.firebaseio.com",
  projectId: "virtual-coup",
  storageBucket: "virtual-coup.appspot.com",
  messagingSenderId: "281959701116",
  appId: "1:281959701116:web:aa0741785e2fe6e5e387f9",
  measurementId: "G-ZQ5YFMQDCZ",
};

firebase.initializeApp(config);
export const { auth } = firebase;
export const db = firebase.database();
export default firebase;
