import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getUsername, setUsername } from "helpers/user";
import { FiX } from "react-icons/fi";
import { db } from "services/firebase";
import { CoupGame, Player } from "helpers/coup";
import styles from "./Name.module.scss";

interface Props {
  handleClose: () => void;
  game: CoupGame;
}
const NameModal = (props: Props) => {
  interface ParamTypes {
    id: string;
  }

  const { id } = useParams<ParamTypes>();
  const { handleClose, game } = props;
  const [username, setLocalUsername] = useState(getUsername());

  const handleSubmit = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    if (!username || !username.trim()) {
      return;
    }

    const { players }: { players: Array<Player> } = game;

    const newPlayers: Array<Player> = [];
    for (let i = 0; i < players.length; i += 1) {
      const player = players[i];
      const { name } = player;
      if (name === getUsername()) {
        const newPlayer = { ...player, name: username };
        newPlayers.push(newPlayer);
      } else {
        newPlayers.push(player);
      }
    }

    try {
      setUsername(username);
      await db.ref(`games/${id}/players`).set(newPlayers);
      handleClose();
    } catch {
      /* */
    }
  };

  return (
    <div className={styles.background} onClick={handleClose}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          Edit username
          <div className={styles.close} onClick={handleClose}>
            <FiX />
          </div>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <input
            className={styles.input}
            value={username}
            onChange={(e) => setLocalUsername(e.target.value)}
          />
          <button type="submit" className={styles.submit}>
            Update username
          </button>
        </form>
      </div>
    </div>
  );
};

export default NameModal;
