import React, { useState } from "react";
import { CoupGame } from "helpers/coup";
import { getUsername } from "helpers/user";
import { Link, useLocation } from "react-router-dom";
import NameModal from "components/modals/Name";
import styles from "./Header.module.scss";

interface HeaderProps {
  game: CoupGame;
}

const Header = (props: HeaderProps) => {
  const { game } = props;
  const [showNameModal, setShowNameModal] = useState(false);
  const location = useLocation();
  const inSettings = location.pathname.includes("settings");

  return (
    <>
      <div className={styles.container}>
        <div className={styles.logo}>Virtual Coup</div>
        <div className={styles.right}>
          {!inSettings ? (
            <Link to={`/play/${game.id}/settings`} className={styles.action}>
              Game Settings
            </Link>
          ) : (
            <Link to={`/play/${game.id}`} className={styles.action}>
              Back to game
            </Link>
          )}
          <div
            className={styles.nameAction}
            onClick={() => {
              setShowNameModal(true);
            }}
          >
            {getUsername()}
          </div>
        </div>
      </div>
      {showNameModal ? (
        <NameModal handleClose={() => setShowNameModal(false)} game={game} />
      ) : null}
    </>
  );
};

export default Header;
