import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getUsername } from "helpers/user";
import { FiX } from "react-icons/fi";
import { db } from "services/firebase";
import { CoupGame, Player, Character } from "helpers/coup";
import styles from "./RemovePlayer.module.scss";

interface Props {
  handleClose: () => void;
  game: CoupGame;
}
const RemovePlayerModal = (props: Props) => {
  interface ParamTypes {
    id: string;
  }
  const [playersToRemove, setPlayersToRemove] = useState<Array<Player>>([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<ParamTypes>();
  const { handleClose, game } = props;
  const { players } = game;

  const handleSubmit = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    setLoading(true);
    const newPlayers: Array<Player> = [];
    const removedCards: Array<Character> = [];
    for (let i = 0; i < players.length; i += 1) {
      const player = players[i];
      let playerShouldBeRemoved = false;
      for (let j = 0; j < playersToRemove.length; j += 1) {
        const playerToRemove = playersToRemove[j];
        if (player.name === playerToRemove.name) {
          playerShouldBeRemoved = true;
          removedCards.push(playerToRemove.cards[0].character);
          removedCards.push(playerToRemove.cards[1].character);
        }
      }
      if (!playerShouldBeRemoved) {
        newPlayers.push(player);
      }
    }

    try {
      await db.ref(`games/${id}/players`).set(newPlayers);
      const newDeck = [...game.currentDeck, ...removedCards];
      await db.ref(`games/${id}/currentDeck`).set(newDeck);

      setLoading(false);
      handleClose();
    } catch {
      setLoading(false);
    }
  };

  const handleSelect = (player: Player) => {
    const indexToRemove = playersToRemove.findIndex((x) => {
      const { name } = x;
      return x && name === player.name;
    });
    const foundPlayer = playersToRemove[indexToRemove];

    if (!foundPlayer) {
      setPlayersToRemove([...playersToRemove, player]);
    } else {
      setPlayersToRemove(playersToRemove.filter((x) => x.name !== player.name));
    }
  };

  const removePlayersButton = () => {
    const ableToRemove = playersToRemove.length;
    if (ableToRemove && !loading) {
      return (
        <div className={styles.removePlayers} onClick={handleSubmit}>
          Remove Players
        </div>
      );
    }

    return <div className={styles.removePlayersDisabled}>Remove Players</div>;
  };

  return (
    <div className={styles.background} onClick={handleClose}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          Remove player(s)
          <div className={styles.close} onClick={handleClose}>
            <FiX />
          </div>
        </div>
        <div className={styles.description}>
          Select players to remove from the game. The selected players will be
          removed and their cards will be returned to the deck.
        </div>
        <div className={styles.content}>
          {players.map((player) => {
            if (player.name === getUsername()) {
              // can't remove yourself
              return null;
            }

            let foundPlayer = false;
            for (let i = 0; i < playersToRemove.length; i += 1) {
              const playerInList = playersToRemove[i];
              if (player.name === playerInList.name) {
                foundPlayer = true;
              }
            }

            let classNames = styles.playerItem;
            if (foundPlayer) classNames = styles.playerItemSelected;

            return (
              <div className={classNames} onClick={() => handleSelect(player)}>
                {player.name}
              </div>
            );
          })}
        </div>
        <div className={styles.actions}>
          <div className={styles.cancel} onClick={handleClose}>
            Cancel
          </div>
          {removePlayersButton()}
        </div>
      </div>
    </div>
  );
};

export default RemovePlayerModal;
