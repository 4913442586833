/* eslint-disable jsx-a11y/no-onchange */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { FiX } from "react-icons/fi";
import { db } from "services/firebase";
import { CoupGame } from "helpers/coup";
import styles from "./RevealCardsModal.module.scss";

interface Props {
  handleClose: () => void;
  game: CoupGame;
}

const RevealCardsModal = (props: Props) => {
  interface ParamTypes {
    id: string;
  }
  const [loading, setLoading] = useState(false);
  const [numberToReveal, setNumberToReveal] = useState(0);
  const { id } = useParams<ParamTypes>();
  const { handleClose, game } = props;
  const { currentDeck, revealedCards = [] } = game;

  const handleSubmit = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    setLoading(true);

    // console.log(numberToReveal);

    const newRevealedCards = currentDeck.splice(0, numberToReveal);
    const newGame: CoupGame = {
      ...game,
      currentDeck,
      revealedCards: revealedCards.concat(newRevealedCards),
    };

    try {
      await db.ref(`games/${id}`).set(newGame);
      setLoading(false);
      handleClose();
    } catch {
      setLoading(false);
    }
  };

  const revealCardsButton = () => {
    if (loading) {
      return <div className={styles.resetGameDisabled}>Revealing...</div>;
    }

    return (
      <div className={styles.resetGame} onClick={handleSubmit}>
        Reveal Cards
      </div>
    );
  };

  return (
    <div className={styles.background} onClick={handleClose}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          Reveal Cards
          <div className={styles.close} onClick={handleClose}>
            <FiX />
          </div>
        </div>
        <div className={styles.description}>
          If you&apos;re playing Coup with fewer than 6 players, you may choose
          to reveal a few cards in the deck up front. This will let you play
          with more information at the start. Note that revealing cards will
          remove them from the game.
        </div>
        {revealedCards && revealedCards.length ? (
          <div className={styles.content}>
            {revealedCards.length} cards currently revealed:{" "}
            {revealedCards.map((card) => `${card} `)}
          </div>
        ) : null}
        <div className={styles.content}>
          Reveal
          <select
            value={numberToReveal}
            onChange={(e) => {
              setNumberToReveal(parseInt(e.target.value, 10));
            }}
            className={styles.select}
          >
            {[...new Array(currentDeck.length)].map((index, idx) => (
              <option value={idx}>{idx}</option>
            ))}
          </select>
          {revealedCards && revealedCards.length ? "more" : null} cards from
          current deck
        </div>
        <div className={styles.actions}>
          <div className={styles.cancel} onClick={handleClose}>
            Cancel
          </div>
          {revealCardsButton()}
        </div>
      </div>
    </div>
  );
};

export default RevealCardsModal;
