import React, { useEffect, useState } from "react";
import { useParams, useHistory, Switch, Route } from "react-router-dom";
import { db } from "services/firebase";
import { CoupGame, Player, MAX_PLAYERS } from "helpers/coup";
import { userInGame, getUsername } from "helpers/user";
import Header from "./Header";
import Game from "./Game";
import Settings from "./Settings";
import styles from "./GameContainer.module.scss";

const GameContainer = () => {
  interface ParamTypes {
    id: string;
  }

  const { id } = useParams<ParamTypes>();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [gameData, setGameData] = useState<CoupGame | undefined>(undefined);

  useEffect(() => {
    // fetch the game once, and if the player is not in the game
    // we need to update the game
    const initializeGame = async () => {
      const snapshot = await db.ref(`games/${id}`).once("value");
      const game = snapshot.val();
      if (game) {
        if (!userInGame(game)) {
          let players = [];
          if (game.players) {
            players = game.players;
          }

          if (players.length >= MAX_PLAYERS) {
            history.replace("/");
            return;
          }

          const characters = game.currentDeck.splice(0, 2);
          const cards = [
            { character: characters[0], revealed: false },
            { character: characters[1], revealed: false },
          ];
          const newPlayer: Player = {
            name: getUsername(),
            coins: 2,
            cards,
          };

          await db.ref(`games/${id}/players`).set([...players, newPlayer]);
          await db.ref(`games/${id}/currentDeck`).set(game.currentDeck);
        }
      }
    };
    initializeGame();

    const fetchGame = async () => {
      try {
        // we want to set game data every time the game updates
        db.ref(`games/${id}`).on("value", async (snapshot) => {
          const game = snapshot.val();
          if (game) {
            console.log(game);
            setGameData(game);
            setLoading(false);
          } else {
            history.replace("/");
          }
        });
      } catch (error) {
        console.error(error);
        setLoading(false);
        history.replace("/");
      }
    };

    fetchGame();
  }, [id, history]);

  const renderGame = () => {
    if (!gameData) return null;

    return (
      <div className={styles.container}>
        <Header game={gameData} />
        <Switch>
          <Route
            path="/play/:id/settings"
            render={() => <Settings game={gameData} />}
          />
          <Route render={() => <Game game={gameData} />} />
        </Switch>
      </div>
    );
  };

  return <div className={styles.wrapper}>{loading ? null : renderGame()}</div>;
};

export default GameContainer;
