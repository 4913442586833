import firebase from "services/firebase";

export const MAX_PLAYERS = 6;

export type Character =
  | "duke"
  | "captain"
  | "ambassador"
  | "assassin"
  | "contessa";

const characters: Array<Character> = [
  "duke",
  "captain",
  "ambassador",
  "assassin",
  "contessa",
];

export interface Card {
  character: Character;
  revealed: boolean;
}

export interface Player {
  name: string;
  coins: number;
  cards: Array<Card>;
}

export interface CoupGame {
  id: String;
  createdAt: firebase.firestore.Timestamp;
  currentDeck: Array<Character>;
  players: Array<Player>;
  revealedCards: Array<Character>;
}

const shuffleDeck = (deck: Array<Character>): Array<Character> => {
  const shuffled = [...deck].sort(() => 0.5 - Math.random());
  return shuffled;
};

export const getNewDeck = (): Array<Character> => {
  const fullDeck: Array<Character> = characters.flatMap((character) => [
    character,
    character,
    character,
  ]);

  // shuffle the deck
  return shuffleDeck(fullDeck);
};
