import React, { useState } from "react";
import { CoupGame, Card, Character } from "helpers/coup";
import { db } from "services/firebase";
import { getUserPlayer } from "helpers/user";
import { useParams } from "react-router-dom";
import styles from "./SwapButtons.module.scss";

interface SwapButtonsProps {
  game: CoupGame;
}

const SwapButtons = (props: SwapButtonsProps) => {
  interface ParamTypes {
    id: string;
  }
  const { game } = props;
  const { players, currentDeck } = game;
  const currentPlayer = getUserPlayer(game);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<ParamTypes>();

  const getRandomCard = (arr: Array<Character>) => {
    const randomSort = arr.sort(() => 0.5 - Math.random());
    const randomCharacter = randomSort.slice(0, 1)[0];
    return {
      randomCharacter,
      restOfDeck: randomSort.slice(1, arr.length),
    };
  };

  const swapCard = async (cardIdx: number) => {
    if (!currentPlayer) return;

    setLoading(true);
    const { cards } = currentPlayer;
    const cardToSwap = cards[cardIdx];
    const characterToSwap = cardToSwap.character;
    const { randomCharacter, restOfDeck } = getRandomCard(currentDeck);

    const newDeck = [characterToSwap, ...restOfDeck].sort(
      () => 0.5 - Math.random()
    );
    const newCards = [];
    for (let i = 0; i < cards.length; i += 1) {
      if (i === cardIdx) {
        const newCard: Card = {
          character: randomCharacter,
          revealed: false,
        };
        newCards.push(newCard);
      } else {
        newCards.push(cards[i]);
      }
    }

    const updatedPlayer = {
      ...currentPlayer,
      cards: newCards,
    };

    const newPlayers = [];
    for (let i = 0; i < players.length; i += 1) {
      const player = players[i];
      if (player.name === currentPlayer.name) {
        newPlayers.push(updatedPlayer);
      } else {
        newPlayers.push(player);
      }
    }

    try {
      await db.ref(`games/${id}/players`).set(newPlayers);
      await db.ref(`games/${id}/currentDeck`).set(newDeck);

      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {currentPlayer?.cards.map((card, idx) => {
        if (loading) {
          return (
            <button type="button" className={styles.button} disabled>
              Loading...
            </button>
          );
        }

        return (
          <button
            type="button"
            className={styles.button}
            onClick={() => swapCard(idx)}
          >
            Swap {card.character}
          </button>
        );
      })}
    </div>
  );
};

export default SwapButtons;
