import React from "react";
import { Link } from "react-router-dom";
import styles from "./NotFound.module.scss";

const NotFound = () => (
  <div className={styles.container}>
    <div className={styles.header}>404</div>
    <div className={styles.description}>
      Sorry - this page could not be found.
    </div>
    <div className={styles.link}>
      Return <Link to="/">home</Link>.
    </div>
  </div>
);

export default NotFound;
