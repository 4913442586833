/* eslint-disable jsx-a11y/no-onchange */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { FiX } from "react-icons/fi";
import { db } from "services/firebase";
import { getUserCards, getUserPlayer } from "helpers/user";
import { CoupGame, Card, Character } from "helpers/coup";
import styles from "./AmbassadorModal.module.scss";

interface Props {
  handleClose: () => void;
  game: CoupGame;
}

const getTwoRandomCards = (arr: any) => {
  const randomSort = arr.sort(() => 0.5 - Math.random());
  const twoRandomCharacters = randomSort.slice(0, 2);
  const twoRandomCards: Array<Card> = [];
  for (let i = 0; i < twoRandomCharacters.length; i += 1) {
    twoRandomCards.push({
      character: twoRandomCharacters[i],
      revealed: false,
    });
  }
  return {
    twoRandomCards,
    leftover: randomSort.slice(2, arr.length),
  };
};

const AmbassadorModal = (props: Props) => {
  interface ParamTypes {
    id: string;
  }
  const [loading, setLoading] = useState(false);
  const [showCards, setShowCards] = useState(false);
  const [error, setError] = useState("");
  const [selectedCardPositions, setSeclectedCardPositions] = useState([0, 1]);
  const { id } = useParams<ParamTypes>();
  const { handleClose, game } = props;
  const { players } = game;
  const { currentDeck = [] } = game;

  const { twoRandomCards, leftover } = getTwoRandomCards(currentDeck);
  // eslint-disable-next-line no-unused-vars,  @typescript-eslint/no-unused-vars
  const [randomCards, setRandomCards] = useState(twoRandomCards);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [restOfDeck, setRestOfDeck] = useState(leftover);
  const playerCards = getUserCards(game);
  const allFourCards = [...playerCards, ...randomCards];

  const handleSubmit = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    setLoading(true);
    if (selectedCardPositions.length !== 2) {
      setError("You must select exactly two cards to keep.");
      setLoading(false);
      return;
    }

    const cardsToKeep: Array<Card> = [];
    const cardsToReturn: Array<Card> = [];
    for (let i = 0; i < allFourCards.length; i += 1) {
      if (selectedCardPositions.includes(i)) {
        cardsToKeep.push(allFourCards[i]);
      } else {
        cardsToReturn.push(allFourCards[i]);
      }
    }

    const currentPlayer = getUserPlayer(game);

    if (!currentPlayer) return;

    const updatedPlayer = {
      ...currentPlayer,
      cards: cardsToKeep,
    };

    const newPlayers = [];
    for (let i = 0; i < players.length; i += 1) {
      const player = players[i];
      if (player.name === currentPlayer.name) {
        newPlayers.push(updatedPlayer);
      } else {
        newPlayers.push(player);
      }
    }

    const newDeck: Array<Character> = [
      ...restOfDeck,
      cardsToReturn[0].character,
      cardsToReturn[1].character,
    ].sort(() => 0.5 - Math.random());

    try {
      await db.ref(`games/${id}/players`).set(newPlayers);
      await db.ref(`games/${id}/currentDeck`).set(newDeck);
      setLoading(false);
      handleClose();
    } catch {
      setLoading(false);
    }
  };

  const ambassadorButton = () => {
    if (loading) {
      return <div className={styles.resetGameDisabled}>Swapping...</div>;
    }

    return (
      <div className={styles.resetGame} onClick={handleSubmit}>
        Swap Cards
      </div>
    );
  };

  const renderAmbassadorCards = () => (
    <>
      <div className={styles.instructions}>
        Select two cards to keep in your hand. The unselected cards will be
        returned to the deck.
      </div>
      <div className={styles.cards}>
        {allFourCards.map((card, idx) => {
          let classNames = styles.card;
          if (selectedCardPositions.includes(idx)) {
            classNames += ` ${styles.selectedCard}`;
          }
          return (
            <div
              className={classNames}
              onClick={() => {
                // can't ambassador if a card is revealed
                if (card.revealed) {
                  return;
                }

                if (selectedCardPositions.includes(idx)) {
                  const newPositions = selectedCardPositions.filter(
                    (x) => x !== idx
                  );
                  setSeclectedCardPositions(newPositions);
                } else {
                  const newPositions = [...selectedCardPositions, idx];
                  setSeclectedCardPositions(newPositions);
                }
              }}
            >
              {card.character}
            </div>
          );
        })}
      </div>
      <div className={styles.actions}>
        <div className={styles.cancel} onClick={handleClose}>
          Cancel
        </div>
        {ambassadorButton()}
      </div>
    </>
  );

  return (
    <div className={styles.background} onClick={handleClose}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          Ambassador
          <div className={styles.close} onClick={handleClose}>
            <FiX />
          </div>
        </div>
        <div className={styles.description}>
          If you Ambassador, you will be able to swap your cards with cards
          available in the deck. Two cards from the deck will be revealed - make
          sure to check that it is allowed by your teammates.
          {!showCards && (
            <div className={styles.continueButtonContainer}>
              <button
                type="button"
                onClick={() => {
                  setError("");
                  setShowCards(true);
                }}
              >
                Continue with Ambassador
              </button>
            </div>
          )}
        </div>
        {showCards ? renderAmbassadorCards() : null}
        {error ? <div className={styles.error}>{error}</div> : null}
      </div>
    </div>
  );
};

export default AmbassadorModal;
