import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CoupGame } from "helpers/coup";
import { FiArrowLeft } from "react-icons/fi";
import styles from "./Settings.module.scss";
import RemovePlayerModal from "./modals/RemovePlayer";
import ResetGameModal from "./modals/ResetGameModal";
import RevealCardsModal from "./modals/RevealCardsModal";

interface Props {
  game: CoupGame;
}

const Settings = (props: Props) => {
  const { game } = props;
  const [showRemovePlayerModal, setShowRemovePlayerModal] = useState(false);
  const [showResetGameModal, setShowResetGameModal] = useState(false);
  const [showRevealCardsModal, setShowRevealCardsModal] = useState(false);

  return (
    <>
      <div className={styles.container}>
        <Link to={`/play/${game.id}`} className={styles.back}>
          <FiArrowLeft className={styles.backIcon} /> Back to game
        </Link>
        <div className={styles.header}>Game Settings</div>
        <div className={styles.description}>
          Use this page to adjust game parameters for all players in case
          anything needs to be edited. <b>Note:</b> these are admin actions that
          will be applied for all players.
        </div>
        <div className={styles.settings}>
          <div className={styles.setting}>
            <div className={styles.action}>
              Reveal cards (for games with fewer players)
            </div>
            <button
              className={styles.button}
              type="button"
              onClick={() => setShowRevealCardsModal(true)}
            >
              Reveal Cards
            </button>
          </div>
          <div className={styles.setting}>
            <div className={styles.action}>Manage players</div>
            <button
              className={styles.button}
              type="button"
              onClick={() => setShowRemovePlayerModal(true)}
            >
              Remove player(s)
            </button>
          </div>
          <div className={styles.setting}>
            <div className={styles.action}>Reset / restart game</div>
            <button
              className={styles.button}
              type="button"
              onClick={() => setShowResetGameModal(true)}
            >
              Reset current game
            </button>
          </div>
        </div>
      </div>

      {/* modals here below */}
      {showRemovePlayerModal ? (
        <RemovePlayerModal
          handleClose={() => setShowRemovePlayerModal(false)}
          game={game}
        />
      ) : null}
      {showResetGameModal ? (
        <ResetGameModal
          handleClose={() => setShowResetGameModal(false)}
          game={game}
        />
      ) : null}
      {showRevealCardsModal ? (
        <RevealCardsModal
          handleClose={() => setShowRevealCardsModal(false)}
          game={game}
        />
      ) : null}
    </>
  );
};

export default Settings;
